<template>
	<div class="container">
    
		<img class="banner" src="../../assets/chat/online-inquiry-banner.jpg" />
    
    <div class="content">
      
      <div class="menus">
        <div class="item" @click="toChat('video')">
          <img src="../../assets/chat/vedio3.png" class="img" />
          <span class="text">视频问诊</span>
        </div>
        <div class="item" @click="toChat('text')">
          <img src="../../assets/chat/tuwen4.png" class="img" />
          <span class="text">图文问诊</span>
        </div>
      </div>
      
      <div class="person-info">
        <h4 class="title">患者信息</h4>
        <div class="item">
          <span class="label">姓名：</span>
          <span class="value">{{patientInfo.patientName}}</span>
        </div>
        <div class="item">
          <span class="label">年龄：</span>
          <span class="value">{{patientInfo.age}}</span>
        </div>
        <div class="item">
          <span class="label">性别：</span>
          <span class="value">{{patientInfo.sex == 1 ? '男':'女'}}</span>
        </div>
      </div>
      
    </div>
    
	</div>
</template>

<script>
  import router from '@/router'
  import { useRoute } from 'vue-router'
  import { EaseChatClient } from '@/IM/initwebsdk'
  import { getPatientConsult, getRanImAccount } from '@/api/index'
  import { ElMessage, ElLoading  } from 'element-plus'
  import { useStore } from 'vuex'
  
  // 意外退出后，聊天缓存没清空，页面加载后默认调登出接口
  const logoutTheUser = () => {
      clearLocalStorage()
      EaseChatClient.close()
  }
  const clearLocalStorage = () => {
      const storageType = ['INFORM', 'conversationList', 'search_hisory',]
      let loginUserId = '';
      try{
          loginUserId = EaseChatClient.user ? EaseChatClient.user : JSON.parse(localStorage.getItem('EASEIM_loginUser')).user;
      }catch(e){
          loginUserId = ''
      }
      const storageKey = `EASEIM_${loginUserId}`
      storageType.map((item) => {
          return window.localStorage.removeItem(`${storageKey}_${item}`)
      })
      window.localStorage.removeItem('EASEIM_loginUser')
  }
  
	export default {
		data() {
			return {
        patientInfo: {},
        store: useStore(),
			}
		},
    methods: {
      toChata(type) {
      	// this.gotoPage('/pages/user/doctor/chat?no=' + this.scanedResult);
        this.scan();
      },
      async toChat() {
        if(!this.patientInfo.consultId){
          this.$message({
            message: '患者信息获取失败',
            type: 'warning'
          });
          return
        }
        this.store.commit('CLEAR_All_MESSAGE')
        // let username = 'qzzl00006';
        // let token = 'YWMtjFteAskiEe2omu0Kt19EXvQKGKm1LkxVkBtSrcZOf4X8cTUAx-ER7YJHC1hw1l4uAwMAAAGHDEzSYgWP1AAig1q7Fy3-xrf6tsfXZ4edRNoo5OTHf4_CeBtOGHQegg'
        // EaseChatClient.open({
        //     user: username,
        //     accessToken: token
        // })
        // window.localStorage.setItem(
        //     'EASEIM_loginUser',
        //     JSON.stringify({
        //         user: username,
        //         accessToken: token
        //     })
        // )
        // router.push('/message?id=qzzl00002&chatType=singleChat')
        // return


        const loadingInstance = ElLoading .service();
        const res = await getRanImAccount(this.patientInfo.consultId)
        if(res.code == 200){
          if(!res.data.patientImInfo.imUserCode){
            ElMessage({
                message: res.data.patientImInfo.imTipMessage,
                type: 'error',
            })
            loadingInstance.close();
            return
          }
          window.IMInfo = res.data
          let username = res.data.patientImInfo.imUserCode;
          let token = res.data.patientImInfo.imUserToken
          EaseChatClient.open({
              user: username,
              accessToken: token
          })
          window.localStorage.setItem(
              'EASEIM_loginUser',
              JSON.stringify({
                  user: username,
                  accessToken: token
              })
          )
          loadingInstance.close();
          let str = `/message?id=${res.data.doctorImInfo.imUserCode}&chatType=singleChat`
          router.push(str)
        }else{
          ElMessage({
              message: res.msg,
              type: 'error',
          })
          loadingInstance.close();
        }
      }
    },
    async mounted() {
      logoutTheUser();
      const route = useRoute();
      const res = await getPatientConsult(route.query.barcode)
      if (res.code == 200) {
          this.patientInfo = res.data
          window.patientInfo = res.data
          window.authToken = res.data.authToken
      }else{
        ElMessage({
            message: res.msg,
            type: 'error',
        })
      }
    }
	}
</script>

<style lang="scss" scoped>
  .container {
    // height: 100%;
    // background-color: #f8f9fa;
  }
  .content {
    padding: 15px;
  }
  .banner {
    width: 100%;
    height: 300rpx;
  }
  .desc-box {
    margin-top: 10px;
    .text {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #19be6b;
    }
  }
  .menus {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    // margin-top: 5px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48%;
      height: 80px;
      border-radius: 20px;
      &:first-child {
        background-color: #02c490;
      }
      &:last-child {
        background-color: #fd7f68;
      }
      .img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        // height: 50px;
      }
      .text {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
      }
    }
  }
  
  .person-info {
    margin-top: 20px;
    padding-bottom: 120px;
    .title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000;
    }
    .item {
      display: flex;
      padding: 12px 0;
      border-bottom: 1px solid #f8f9fa;
      .label {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000;
        min-width: 70px;
        display: block;
        margin-right: 16px;
      }
      .value {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #999;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  
</style>