<template>
  <div class="container">
    <img class="banner" src="../../assets/chat/online-inquiry-banner.jpg" alt="">
    <!-- <div>
      <span>扫描</span>
    </div> -->
    <scanner @scaned="scan"></scanner>
  </div>
</template>

<script>
  // import { ElMessage } from 'element-plus'
  import { EaseChatClient } from '@/IM/initwebsdk'
  import scanner from './scanner.vue';
  export default {
    components: {
      scanner
    },
    methods: {
      startRecognize(){
      	setTimeout(() => {
          scan = new plus.barcode.Barcode('bcid');
          scan.onmarked = onmarked;
          this.startScan()
        }, 300)
      },
      startScan(){
      	scan.start();
      },
      scan() {
        let username = 'user1';
        let token = 'YWMtlCIrjLwiEe24WWsFaUiNJ_QKGKm1LkxVkBtSrcZOf4UyRuPQvCIR7Zhl7Y2xOHkoAwMAAAGGtxqj3TeeSACIutxUWgRs5buHojItWTNx89o86pAdwAbIaaim6gicIA'
        EaseChatClient.open({
            user: username,
            accessToken: token
        })
        window.localStorage.setItem(
            'EASEIM_loginUser',
            JSON.stringify({
                user: username,
                accessToken: token
            })
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    font-size: 0;
    overflow: hidden;
    .banner {
      width: 100%;
      height: 200px;
    }
  }
</style>