import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import store from '@/store'
import Scan from '../views/Scan'
import Index from '../views/Index/index'
import Message from '../views/Chat/components/Message'
import HistoryMessage from '../views/Index/historyMessage'
console.log(store.state.loginState)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
    },
    {
        path: '/historyMessage',
        name: 'historyMessage',
        component: HistoryMessage,
    },
    {
        path: '/message',
        name: 'message',
        component: Message,
    },
    {
        path: '/scan',
        name: 'scan',
        component: Scan,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})
router.beforeEach((to, from, next) => {
    NProgress.start()
    // const loginState = store.state.loginState
    const EASEIM_loginUser = window.localStorage.getItem('EASEIM_loginUser')
    const loginUserFromStorage = JSON.parse(EASEIM_loginUser) || {}
    if (to.matched.some((record) => record.meta.requiresAuth)) {
    //需要登陆
        if (loginUserFromStorage.user && loginUserFromStorage.accessToken) {
            //token存在，放行
            console.log(to)
            console.log(from)
            next()
            NProgress.done()
        } else {
            //token不存在，跳转登陆页面
            next({ path: '/scan' })
            NProgress.done()
        }
    } else {
        next()
        NProgress.done()
    }
})
export default router
