<script setup>
import { reactive, ref, computed, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useClipboard, usePermission } from '@vueuse/core'
import { ElMessage } from 'element-plus'
import { EaseChatClient } from '@/IM/initwebsdk'
import BenzAMRRecorder from 'benz-amr-recorder'
import fileSizeFormat from '@/utils/fileSizeFormat'
import dateFormat from '@/utils/dateFormater'
import { messageType } from '@/constant'
import {
    handleSDKErrorNotifi
} from '@/utils/handleSomeData'
/* 默认头像 */
import defaultAvatar from '@/assets/images/avatar/theme2x.png'
import ReportMessage from './suit/reportMessage.vue'
/* vuex store */
const store = useStore()
/* props */
const props = defineProps({
    messageData: {
        type: [Array, Object],
        default: () => [],
    },
    nowPickInfo: {
        type: Object,
        default: () => ({})
    },
    fromId: {
        type: String,
        default: ''
    },

})
/* emits */
const emit = defineEmits(['scrollMessageList', 'reEditMessage'])
const { messageData, fromId} = toRefs(props)
/* constant */
const { ALL_MESSAGE_TYPE, CUSTOM_TYPE } = messageType
/* login hxId */
const loginUserId = EaseChatClient.user

/* computed-- 消息来源是否为自己 */
const isMyself = computed(() => {
    return (msgBody) => {
        return fromId.value?(msgBody.from === fromId.value):(msgBody.from === loginUserId)
    }
})
/* 获取自己的用户信息 */
const loginUserInfo = computed(() => store.state.loginUserInfo)

/* 获取他人的用户信息 */
const otherUserInfo = computed(() => {
    return (otherId) => {
        const otherInfos = store.state.Contacts.friendList[otherId] || { avatarurl: defaultAvatar }
        return otherInfos
    }
}
)

/* 处理时间显示间隔 */

const handleMsgTimeShow = computed(() => {
    return (time, index) => {
        const msgList = Array.from(messageData.value)
        if (index !== 0) {
            const lastTime = msgList[index - 1].time
            if (time - lastTime > 50000) {
                return dateFormat('MM/DD/HH:mm', time)
            } else {
                return false
            }
        } else {
            return dateFormat('MM/DD/HH:mm', time)
        }
        return time
    }
})
//音频播放状态
const audioPlayStatus = reactive({
    isPlaying: false,//是否在播放中
    playIndex: -1, //在播放的音频消息下标
})
//开始播放
const startplayAudio = (msgBody, index) => {
    const armRec = new BenzAMRRecorder()
    const src = msgBody.url
    audioPlayStatus.playIndex = index
    console.log('>>>>>开始播放音频', msgBody.url)
    //初始化音频源并调用播放
    armRec.initWithUrl(src).then(() => {
        if (!audioPlayStatus.isPlaying) {
            armRec.play()
        }
    })
    //播放开始监听
    armRec.onPlay(() => {
        audioPlayStatus.isPlaying = true
        audioPlayStatus.playIndex = index
    })
    //播放结束监听
    armRec.onStop(() => {
        audioPlayStatus.isPlaying = false
        audioPlayStatus.playIndex = -1
    })
}


//复制文本
// const permissionRead = usePermission('clipboard-read') //请求剪切板读的权限
// const permissionWrite = usePermission('clipboard-write') //请求剪切板写的权限
const { copy, copied, isSupported } = useClipboard() //copy 复制方法 copied 是否已经复制 isSupported 是否支持剪切板
const copyTextMessages = (msg) => {
    copy(msg)
    if (copied) {
        ElMessage({
            type: 'success',
            message: '成功复制到剪切板',
            center: true,
        })
        console.log('>>>>>成功复制')
    }
}
//撤回消息
const recallMessage = async ({ id, to, chatType }) => {
    const options = {
        mid: id,
        to: to,
        chatType: chatType
    }
    try {
        await store.dispatch('recallMessage', options)
    } catch (error) {
        handleSDKErrorNotifi(error.type, error.message)
        console.log('>>>>>>撤回失败', error)
    }

}
//删除消息（非从服务器删除）
const deleteMessage = ({ from, to, id: mid }) => {
    const key = to === EaseChatClient.user ? from : to
    store.commit('CHANGE_MESSAGE_BODAY', { type: 'deleteMsg', key, mid })
}
// 消息举报
const reportMessage = ref(null)
//举报消息
const informOnMessage = (msgBody) => {
    console.log('>>>>调用举报')
    reportMessage.value.alertReportMsgModal(msgBody)
}
//父组件重新编辑方法
const reEdit = (msg) => emit('reEditMessage', msg)
</script>
<template>
    <div>
        <div class="messageList_box" v-for="(msgBody, index) in messageData" :key="msgBody.id">
            <div v-if="!msgBody.isRecall && msgBody.type !== ALL_MESSAGE_TYPE.INFORM" class="message_box_item"
                :style="{ flexDirection: (isMyself(msgBody) ? 'row-reverse' : 'row') }">
                <div class="message_item_time">{{ handleMsgTimeShow(msgBody.time, index) || '' }}</div>
                <el-avatar class="message_item_avator"
                    :src="isMyself(msgBody) ? loginUserInfo.avatarurl : otherUserInfo(msgBody.from).avatarurl || defaultAvatar">
                </el-avatar>
                <el-dropdown class="message_box_content"
                    :class="[isMyself(msgBody) ? 'message_box_content_mine' : 'message_box_content_other']"
                    :style="{background:isMyself(msgBody)?'':fromId?'#f0f0f0':''}"
                    trigger="contextmenu" placement="bottom-end">
                    <!-- 文本类型消息 -->
                    <p style="padding: 10px;line-height:24px" v-if="msgBody.type === ALL_MESSAGE_TYPE.TEXT">
                        {{ msgBody.msg }}
                    </p>
                    <!-- 图片类型消息 -->
                    <!-- <div> -->
                    <el-image v-if="msgBody.type === ALL_MESSAGE_TYPE.IMAGE" style="border-radius:5px;"
                        :src="msgBody.thumb" :preview-src-list="[msgBody.url]" :initial-index="1" fit="cover" />
                    <!-- </div> -->
                    <!-- 语音类型消息 -->
                    <div :class="['message_box_content_audio', isMyself(msgBody) ? 'message_box_content_audio_mine' : 'message_box_content_audio_other']"
                        v-if="msgBody.type === ALL_MESSAGE_TYPE.AUDIO" @click="startplayAudio(msgBody, index)"
                        :style="`width:${msgBody.length * 10}px`">
                        <span class="audio_length_text">
                            {{ msgBody.length }}′′
                        </span>
                        <div :class="[isMyself(msgBody) ? 'play_audio_icon_mine' : 'play_audio_icon_other', audioPlayStatus.playIndex === index && 'start_play_audio']"
                            style=" background-size: 100% 100%;">
                        </div>
                    </div>
                    <div v-if="msgBody.type === ALL_MESSAGE_TYPE.LOCAL">
                        <p style="padding: 10px">[暂不支持位置消息展示]</p>
                    </div>
                    <!-- 文件类型消息 -->
                    <div v-if="msgBody.type === ALL_MESSAGE_TYPE.FILE" class="message_box_content_file">
                        <div class="file_text_box">
                            <div class="file_name">{{ msgBody.filename }}</div>
                            <div class="file_size">{{ fileSizeFormat(msgBody.file_length) }}</div>
                            <a class="file_download" :href="msgBody.url" download>点击下载</a>
                        </div>
                        <span class="iconfont icon-wenjian"></span>
                    </div>
                    <!-- 自定义类型消息 -->
                    <div v-if="msgBody.type === ALL_MESSAGE_TYPE.CUSTOM" class="message_box_content_custom">
                        <!-- 医生信息 start -->
                        <div class="custom-doc-box" v-if="msgBody.ext.type == 'doctor'">
                            <header class="custom-head">
                                <el-icon color="#3175c0" :size="20"><Avatar /></el-icon>
                                <b>医生信息</b>
                            </header>
                            <div class="custom-doc-content">
                                <div class="doc-info">
                                    <p class="doc-user-a">
                                        <b>{{ msgBody.ext.msg.doctorName }}</b>
                                        <span>{{ msgBody.ext.msg.departmentName }}</span>
                                    </p>
                                    <p class="doc-user-b">
                                        <b>{{ msgBody.ext.msg.hospitalName }}</b>
                                    </p>
                                    <!-- <p class="doc-user-c">简介：擅长心脑血管，脾胃肺病，内分泌，妇产科疾病。深受病人的喜爱和赞赏</p> -->
                                </div>
                            </div>
                            <!-- <footer class="custom-foot-list">
                                <div class="custom-foot-item" v-for="(i,index) in 3" :key="index">
                                    <b>6年8个月</b>
                                    <span>从业时间</span>
                                </div>
                            </footer> -->
                        </div>
                        <!-- 医生信息 end -->
                        <!-- 问诊信息 start -->
                        <div class="custom-sick-box" v-if="msgBody.ext.type == 'patient'">
                            <header class="custom-head">
                                <el-icon color="#3175c0" :size="18"><Memo /></el-icon>
                                <b>问诊信息</b>
                            </header>
                            <div class="custom-sick-content">
                                <div class="sick-up-info">
                                    <span class="sick-title">患者：</span>
                                    <span class="sick-user-info">
                                        <b style="margin-right: 10px;">{{msgBody.ext.msg.patientName}}</b>
                                        <b style="margin-right: 10px;">{{msgBody.ext.msg.sex == 1 ? '男' : '女' }}</b>
                                        <b>{{msgBody.ext.msg.age}}岁</b>
                                    </span>
                                </div>
                                <!-- <div class="sick-down-info">
                                    <p>
                                        <span class="sick-title">病情描述：</span>
                                        <b>感冒</b>
                                    </p>
                                    <p>
                                        <span class="sick-title">药品需求：</span>
                                        <b>[言诺欣]阿莫西林胶表0,25g*20粒/盒 x 1</b>
                                    </p>
                                    <p class="sick-desc">
                                        <el-icon><WarningFilled color="#a9a9a9" /></el-icon>
                                        <span class="sick-desc-txt">确认已在线下就诊，使用过所购买药品且无过敏或不良反应，当前病情稳定</span>
                                    </p>
                                </div> -->
                            </div>
                        </div>
                        <!-- 问诊信息 end -->
                        <!-- 电子处方 start -->
                        <div class="custom-card-box" v-if="msgBody.ext.type == 'prescription'">
                            <header class="custom-card-head">
                                <p class="card-title">
                                    <el-icon color="#3175c0" :size="18"><Document /></el-icon>
                                    <b>电子处方</b>
                                </p>
                                <div class="card-head-box">
                                    <span class="card-finish">已开方</span>
                                    <p class="card-old-box">
                                        <span>原始处方</span>
                                        <el-icon color="#5a9df8" :size="16"><ArrowRight /></el-icon>
                                    </p>
                                </div>
                            </header>
                            <div class="custom-card-content">
                                <div class="card-up-info">
                                    <p>
                                        <span class="card-title">患者：</span>
                                        <span class="card-info-txt">张三</span>
                                    </p>
                                    <p>
                                        <span class="card-title">诊断：</span>
                                        <span class="card-info-txt">胃病</span>
                                    </p>
                                    <p>
                                        <span class="card-title">开方时间：</span>
                                        <span class="card-info-txt">2023-03-09 22:24:43</span>
                                    </p>
                                </div>
                                <div class="card-down-info">
                                    <p class="goods-box" v-for="(i,index) in 5" :key="index">
                                        <span class="goods-info">
                                            <b class="goods-name">诺氟沙星胶囊</b>
                                            <span class="goods-type">0.1g*24粒/盒</span>
                                        </span>
                                        <b class="goods-num">x 1</b>
                                    </p>
                                    <p class="goods-way">口服,一日2次，每次4粒</p>
                                </div>
                            </div>
                        </div>
                        <!-- 电子处方 end -->
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-if="msgBody.type === ALL_MESSAGE_TYPE.TEXT && isSupported"
                                @click="copyTextMessages(msgBody.msg)">
                                复制
                            </el-dropdown-item>
                            <el-dropdown-item v-if="isMyself(msgBody)" @click="recallMessage(msgBody)">
                                撤回
                            </el-dropdown-item>
                            <el-dropdown-item @click="deleteMessage(msgBody)">
                                删除
                            </el-dropdown-item>
                            <el-dropdown-item v-if="!isMyself(msgBody)" @click="informOnMessage(msgBody)">
                                举报
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>

                </el-dropdown>
            </div>
            <div v-if="msgBody.isRecall" class="recall_style">{{ isMyself(msgBody) ? "你" : `${msgBody.from}`
            }}撤回了一条消息<span class="reEdit" v-show="isMyself(msgBody) && msgBody.type === ALL_MESSAGE_TYPE.TEXT"
                    @click="reEdit(msgBody.msg)">重新编辑</span></div>
            <div v-if="msgBody.type === ALL_MESSAGE_TYPE.INFORM" class="inform_style">
                <p>
                    {{ msgBody.msg }}
                </p>
            </div>

        </div>
        <ReportMessage ref="reportMessage" />
    </div>


</template>

<style lang="scss" scoped>
.messageList_box {
    width: 100%;

    .message_box_item {
        position: relative;
        display: flex;
        margin: 32px auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #333333;

        .message_item_time {
            position: absolute;
            top: -25px;
            left: 0;
            right: 0;
            margin: auto;
            width: 74px;
            height: 20px;
            color: #ADADAD;
            font-weight: 400;
            font-size: 10px;
            line-height: 20px;

        }

        .message_item_avator {
            width: 38px;
            height: 38px;

        }

        .message_box_content {
            display: flex;
            align-items: center;
            max-width: calc(100% - 50px);
            min-height: 34px;
            margin: 0 6px;
            word-break: break-all;

            /* 通用音频播放样式 */
            .message_box_content_audio {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                max-width: 250px;
                min-width: 80px;
                font-size: 12px;

                .audio_length_text {
                    font-family: 'Avenir';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                }
            }

            /* 对方音频播放样式 */
            .message_box_content_audio_other {
                flex-direction: row;

                @keyframes other_play_icon {
                    0% {
                        background: url('@/assets/images/playAudio/msg_recv_audio02@3x.png') no-repeat;

                        background-size: 100% 100%;
                    }

                    50% {
                        background: url('@/assets/images/playAudio/msg_recv_audio01@3x.png') no-repeat;

                        background-size: 100% 100%;
                    }

                    100% {
                        background: url('@/assets/images/playAudio/msg_recv_audio@3x.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }

                .play_audio_icon_other {
                    width: 30px;
                    height: 30px;
                    background: url('@/assets/images/playAudio/msg_recv_audio@3x.png') no-repeat;
                    margin-right: 10px;
                }

                .start_play_audio {
                    animation: other_play_icon 2s;
                    animation-iteration-count: infinite;
                }
            }

            /* 己方音频播放样式 */
            .message_box_content_audio_mine {
                flex-direction: row-reverse;

                @keyframes mine_play_icon {
                    0% {
                        background: url('@/assets/images/playAudio/msg_send_audio02@3x.png') no-repeat;

                        background-size: 100% 100%;
                    }

                    50% {
                        background: url('@/assets/images/playAudio/msg_send_audio01@3x.png') no-repeat;

                        background-size: 100% 100%;
                    }

                    100% {
                        background: url('@/assets/images/playAudio/msg_send_audio@3x.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }

                .play_audio_icon_mine {
                    width: 30px;
                    height: 30px;
                    background-size: 100% 100%;
                    background: url('@/assets/images/playAudio/msg_send_audio@3x.png') no-repeat;
                    margin-left: 10px;
                }

                .start_play_audio {
                    animation: mine_play_icon 2s;
                    animation-iteration-count: infinite;
                }
            }

            /* 文件消息样式 */
            .message_box_content_file {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 200px;
                min-height: 60px;
                max-height: 120px;
                padding: 10px;

                .file_text_box {
                    width: 75%;
                    height: 80%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .file_name {
                        width: 120px;
                        white-space: wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .file_size {
                        font-size: 13px;
                    }

                    .file_download {
                        width: 100%;
                        color: #333333;
                        font-size: 13px;
                        cursor: pointer;
                        transition: all .3s ease;

                        &:hover {
                            transform: scale(0.9);
                        }
                    }
                }

                .icon-wenjian {
                    font-size: 50px;
                    color: #8d8a8a;
                }
            }

            /* 自定义消息 */
            .message_box_content_custom {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                min-height: 60px;
                // max-height: 120px;
                padding: 10px;
                overflow: hidden;

                .user_card_main {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    color: #333333;
                    font-size: 17px;

                    .nickname {
                        display: inline-block;
                        // width: 100%;
                        margin-left: 10px;
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }

            /* 个人名片 */
        }

        .message_box_content_other {
            background: #FFF;
            border-radius: 8px 8px 8px 0px;
        }

        .message_box_content_mine {
            background: #C1E3FC;
            border-radius: 8px 0px 8px 8px;
        }

    }

    /* 撤回或者系统通知类消息 */
    .recall_style,
    .inform_style {
        height: 60px;
        text-align: center;
        color: #aaaaaa;
        font-size: 10px;
        margin: 5px 0;

        .reEdit {
            color: #3E91FA;
            margin-left: 3px;
            cursor: pointer;
        }
    }

}

:deep(.el-input__wrapper) {
    border-radius: 5px;
}

:deep(.el-dialog__header) {
    background: #F2F2F2;
    margin: 0;
}

/* 自定义 医生简介消息 */
.custom-head {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    b {
        font-size: 16px;
        padding-left: 5px;
    }
}
.custom-doc-box {
    .custom-doc-content {
        display: flex;
        flex-flow: row nowrap;
        padding: 15px 0;
    }
    .doc-info {
        flex:1;
        margin-left: 10px;
    }
    .doc-user-a {
        b {
            font-size: 16px;
        }
        span {
            font-size: 14px;
            padding-left: 5px;
            span {
                font-size: 13px;
            }
        }
    }
    .doc-user-b {
        padding: 8px 0 10px;
        b {
            font-size: 14px;
            line-height: 1.2;
        }
        b + b{
            padding: 0 3px;
        }
    }
    .doc-user-c {
        font-size: 13px;
        line-height: 1.5;
        color: rgba(0, 0, 0,0.4);
    }
    .custom-foot-list {
        padding: 10px 0;
        background: #f9f9f9;
        display: flex;
        flex-flow: row nowrap;
        .custom-foot-item {
            width: 33.33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            b:first-child {
                padding-bottom: 10px;
            }
            span {
                color: rgba(0, 0, 0,0.4);
            }
        }
        .custom-foot-item + .custom-foot-item {
            border-left:1px solid rgba(0, 0, 0,0.1);
        }
    }
}

/* 自定义 患者简介消息 */
.custom-sick-content {
    .sick-title {
        font-size: 14px;
        color: rgba(0, 0, 0,0.5);
    }
     .sick-up-info {
        padding: 10px 0;
        display: flex;
        flex-flow: row wrap;
        line-height: 1.2;
        // border-bottom: 1px solid rgba(0, 0,0,0.1);
        .sick-user-info {
            display: flex;
            flex-flow: row nowrap;
            b + b {
                font-size: 14px;
                padding: 0 4px;
            }
        }
     }
     .sick-down-info {
        padding: 10px 0 0;
        p {
            line-height: 1.2;
            &:not(:first-child) {
                padding-top: 10px;
            }
        }
        .sick-desc {
            display: flex;
            .sick-desc-txt {
                font-size: 13px;
                line-height: 1.5;
                padding-left: 5px;
                color: #00000080;
            }
        }
     }
}

 /* 电子处方 */
.custom-card-box {
    .custom-card-content {
        .card-up-info {
                padding: 15px 0;
            p {
                line-height: 1.2;
                &:not(:first-child) {
                    padding-top: 8px;
                }
            }
            .card-info-txt, .card-title{
                font-size: 14px;
                color: rgba(0, 0, 0,0.5);
            }
        }
        .card-down-info {
            .goods-box {
                display: flex;
                justify-content: space-between;
                padding-bottom: 10px;
                .goods-info {
                    flex: 1;
                    .goods-name {
                        font-size: 14px;
                    }
                    .goods-type {
                        font-size: 14px;
                        padding-left: 5px;
                    }
                }
                .goods-num {
                    width: 30px;
                }
            }
            .goods-way {
                font-size: 14px;
                padding-top: 5px;
                color: rgba(0, 0, 0,0.5);
            }
        }
        
    }
    .custom-card-head {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .card-title {
            display: flex;
            flex-flow: row nowrap;
            b {
                font-size: 16px;
                padding-left: 5px;
            }
        }
        .card-head-box {
            display: flex;
            flex-flow: row nowrap;
            .card-finish {
                padding: 2px 4px;
                border: 1px solid #61ae98;
                background: #61ae98;
                color: #fff;
                border-radius: 2px;
            }
        }
        .card-old-box {
            padding-left: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
                font-size: 14px;
                color: #5a9df8;
            }
        }
    }
}

</style>
