/* 用作根据消息类型处理对象中的key */
import { EaseChatClient } from '@/IM/initwebsdk'
import { messageType } from '@/constant'
const { CHAT_TYPE } = messageType
export default function (msgBody) {
    const loginUserId = EaseChatClient.user
    console.log(msgBody)
    console.log(msgBody.to)
    console.log(loginUserId)
    console.log(msgBody.chatType === CHAT_TYPE.SINGLE)
    console.log(msgBody.to === loginUserId)
    const listKey =
    msgBody.chatType === CHAT_TYPE.SINGLE
        ? msgBody.to === loginUserId
            ? msgBody.from
            : msgBody.to
        : msgBody.to
    console.log(listKey)
    return listKey
}
