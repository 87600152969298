import request from '@/utils/myRequest'


export function getPatientConsult(params) {
    return request({
        url: 'freed-pcs/pcs/intf/patient/getPatientConsult?consultId='+params,
        method: 'get'
    })
}

export function getRanImAccount(params) {
    return request({
        url: 'freed-pcs/pcs/intf/im/allotDoctorAndImAccount?consultId='+params,
        method: 'get'
    })
}

export function createImTextMessage(params) {
    return request({
        url: 'freed-pcs/pcs/intf/im/createImTextMessage',
        method: 'post',
        data: params
    })
}

export function listImHistoryMessage(params) {
    return request({
        url: 'freed-pcs/pcs/hospital/pcsPatientConsult/listImHistoryMessage?consultId='+params,
        method: 'get'
    })
}

// 云端录制开始
export function agoraStart(params) {
    return request({
        url: 'freed-open/tss/im/video/agoraStart',
        method: 'post',
        data: params
    })
}

// 云端录制结束
export function agoraStop(params) {
    return request({
        url: 'freed-open/tss/im/video/agoraStop',
        method: 'post',
        data: params
    })
}

// 生成视频token数据
export function buildToken(params) {
    return request({
        url: 'freed-open/tss/im/video/buildToken',
        method: 'post',
        data: params
    })
}

