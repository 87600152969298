import AgoraRTC from 'agora-rtc-sdk-ng'


const AgoraAppId = '5d180bd82def49bc8b1f9d4506ebc4e8'
//设置日志输出等级
AgoraRTC.setLogLevel(3)

export {
    AgoraAppId,
    AgoraRTC
}
